/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-04-02 12:04:25
 * @modify date 2019-04-02 12:04:25
 * @desc [description]
*/

import React from 'react';
import { Transition, Modal, Form, Grid, Message } from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import Button from '../button/button';

import styles from './bookappointment.module.scss';

export default class BookAppointment extends React.Component {

    render() {

        const { modalOpen, _closeModal, formValues, _updateFormValues, _submit, errors, loading, options } = this.props;

        return (
            <Transition duration={300} visible={modalOpen} animation="slide down">
                <Modal className={styles.modal} open={modalOpen} onClose={_closeModal} closeIcon>
                    <Grid>
                        <Grid.Row columns={"equal"}>
                            
                            <Grid.Column mobile={16} computer={8} tablet={8} largeScreen={8}>
                                <Modal.Description className={styles.description}>
                                    <h2 className={styles.header}>Book appointment with one of our architects.</h2>
                                    <Form className={styles.form}>

                                        <Form.Field>
                                            <label>Name</label>
                                            <Form.Input 
                                                className={styles.input}
                                                error={errors.hasOwnProperty('name')}
                                                value={formValues.name} 
                                                name="name" 
                                                onChange={e => _updateFormValues(e.target.value, 'name')} 
                                            />
                                            {
                                                errors.hasOwnProperty('name') ? <Message className={styles.errorMsg} size='mini' content={errors.name} /> : null
                                            }
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Email</label>
                                            <Form.Input 
                                                className={styles.input}
                                                error={errors.hasOwnProperty('email')}
                                                value={formValues.email} 
                                                name="email" 
                                                onChange={e => _updateFormValues(e.target.value, 'email')} 
                                            />
                                            {
                                                errors.hasOwnProperty('email') ? <Message className={styles.errorMsg} size='mini' content={errors.email} /> : null
                                            }
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Contact Number</label>
                                            <Form.Input 
                                                className={styles.input}
                                                error={errors.hasOwnProperty('contactNumber')}
                                                value={formValues.contactNumber} 
                                                name="concactNumber"  
                                                onChange={e => _updateFormValues(e.target.value, 'contactNumber')} 
                                            />
                                            {
                                                errors.hasOwnProperty('contactNumber') ? <Message className={styles.errorMsg} size='mini' content={errors.contactNumber} /> : null
                                            }
                                        </Form.Field>
                                        <Form.Field>
                                            <label>What are you interested in ?</label>
                                            <Form.Select 
                                                className={styles.input}
                                                value={formValues.interestedIn} 
                                                name="interestedIn" 
                                                options={options}  
                                                onChange={(e, {value}) => _updateFormValues(value, 'interestedIn')} 
                                            />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Preferred Date and Time</label>
                                            <DateTimeInput 
                                                className={styles.input}
                                                value={formValues.dateTime}
                                                name="dateTime"
                                                iconPosition="left"
                                                timeFormat="ampm"
                                                closable={true}
                                                minDate={new Date()}
                                                onChange={(e, {value}) => _updateFormValues(value, 'dateTime')} 
                                            />
                                        </Form.Field>

                                        <Button loading={loading} primary content="Book Appointment" onClick={_submit} />
                                    </Form>
                                </Modal.Description>
                            </Grid.Column>
                            
                            <Grid.Column width={8} className={styles.sideImage} stretched only='tablet computer largescreen wide screen'>
                                
                            </Grid.Column>

                        </Grid.Row>
                    </Grid>
                </Modal>
            </Transition>
        );

    }

}