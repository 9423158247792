/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-04-02 12:13:21
 * @modify date 2019-04-02 12:13:21
 * @desc [description]
*/

import React from 'react';
import BookAppointment from './bookappointment';
import request from '../../api/request';
import iziToast from 'izitoast';

export default class BookAppointmentContainer extends React.Component {

    constructor() {
        super();

        this.state = {
            formValues: {
                name: '',
                email: '',
                contactNumber: '',
                interestedIn: '',
                dateTime: '',
            },
            options: [],
            errors: {},
            loading: false
        }
    }

    componentDidMount = () => {
        this._getCategories();
    }

    _getCategories = () => {

        let apiCall = request('/getCategory', "get")

        apiCall.then((result) => {
            // console.log(result.data);
            let options = this.state.options;
            options = result.data && result.data.map((required) => {
                 
                return {
                    key: required.category,
                    text: required.category,
                    value: required.category
                }
            })
            this.setState({
                options
            })
        })
        
        .catch((error) => {
            console.log(error);
        })
    }

    _validate = () => {

        var regularExpression = {
            personNameCheck : /^[a-zA-Z/.]+(([', -][a-zA-Z/. ])?[a-zA-Z/.]*)*$/,
            emailCheck : /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
            phonenumberCheck : /^[0-9/+]+(([', -][0-9/+ ])?[0-9/+]*)*$/
        }

        let { name, email, contactNumber } = this.state.formValues, errors = {};

        name = name.trim();
        email = email.trim();
        contactNumber = contactNumber.trim();

        if(!regularExpression.personNameCheck.test(name) || name === '') {
            errors.name = "Not a valid name. Valid must contain only alphabets.";
        };

        if(!regularExpression.emailCheck.test(email) || email === '') {
            errors.email = "Not a valid email address.";
        };

        if(!regularExpression.phonenumberCheck.test(contactNumber) || contactNumber === ''){
            errors.contactNumber = "Not a valid contact number.";
        } 
        
        return errors;
    
    }

    _updateFormValues = (value, field) => {

        let {formValues} = this.state;

        formValues[field] = value;

        if(field === 'interestedIn') {
            this.props._openModal('');
        }


        this.setState({
            formValues,
        });

    }

    _submit = () => {

        let errors = this._validate();
        // console.log(errors.length);

        // console.log(errors);

        if(Object.keys(errors).length !== 0) {
            this.setState({
                errors
            });
        }
        else{

            this.setState({
                loading: true
            });

            request('bookAppointment', 'post', this.state.formValues)
            .then(res => {

                
                this.setState({
                    loading: false
                });

                iziToast.success({
                    message: "Thank you for contacting us, we will react out to you very soon.",
                    position: 'topRight',
                    timeout: 5000,
                    transitionIn: 'fadeInLeft',
                    transitionOut: 'fadeOutRight'
                });

            })
            .catch(err => {
                
                this.setState({
                    loading: false
                });

                iziToast.error({
                    message: 'Your request could not be processed, please try again.',
                    position: 'topRight',
                    timeout: 5000,
                    transitionIn: 'fadeInLeft',
                    transitionOut: 'fadeOutRight'
                });
            });
        }

    }

    render() {

        const { formValues, errors, loading, options } = this.state;
        const { modalOpen, _closeModal, service } = this.props;

        formValues.interestedIn = service !== '' ? service : formValues.interestedIn ? formValues.interestedIn : '';

        return (

             <BookAppointment 
                errors={errors}
                formValues={formValues}
                loading={loading}
                modalOpen={modalOpen} 
                _closeModal={_closeModal} 
                _updateFormValues={this._updateFormValues}
                _submit={this._submit}
                options={options}
            />

        );

    }

}