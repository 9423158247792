/**
 * @author [Sujan Basnet]
 * @email [basnetsujan95@gmail.com]
 * @create date 2019-03-25 11:50:33
 * @modify date 2019-03-25 11:50:33
 * @desc [description]
*/

import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loader, Dimmer } from 'semantic-ui-react';
import request from './api/request'; 

import Navbar from './components/navbar/navbar';
import BookAppointment from './components/bookappointment/bookappointment.container';

const HomeContainer = lazy(() => import('./pages/home/homecontainer'));
const Projects = lazy(() => import('./pages/projects/projects.container'));
const About = lazy(() => import('./pages/about/about.container'));
const ContactContainer = lazy(() => import('./pages/contact/contactcontainer'));

const NotFound = lazy(() => import('./pages/notfound/notfound'));

export default class App extends React.Component {

    constructor() {
        super();
        this.state = {
            modalOpen: false,
            facebookPageId: ''
        }
    }

    _openModal = (service = '') => {

        this.setState({
            modalOpen: true,
            service: typeof service === 'object' ? '' : service
        });
    }

    _closeModal = () => {
        this.setState({
            modalOpen: false,
            service: undefined
        });
    }

    componentDidMount = () => {
        this._getFacebookPageId();
       
    }

    _getFacebookPageId = () => {

        request('facebookpageid')
        .then((result) => {
            this.setState({
                facebookPageId: result.data.facebookPageId
            }, () => { this._facebookMessenger(); });
        })
        .catch(error => {
            console.log(error);
            console.log("Cannot get facebook page ID");
        });
    }

    _facebookMessenger =() => {
    
        window.fbAsyncInit = function() {
            window.FB.init({
            appId            : '1080703522103972',
            autoLogAppEvents : true,
            xfbml            : true,
            version          : 'v3.2'
            });
        };

        (function(d, s, id){
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {return;}
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));

    }

    render() {

        const { modalOpen, service, facebookPageId } = this.state;

        return (

            <React.Fragment>
                {/* for skyline page_id is 553632821407916 */}
                {/* for justfortry page_id is 804101499934078 */}
                <div 
                    className="fb-customerchat"
                    attribution="setup_tool"
                    page_id={facebookPageId}
                    theme_color="#0095DA"
                >
                </div>
                <Navbar _openModal={this._openModal} _closeModal={this._closeModal} />

                <Suspense 
                    fallback={
                        <Dimmer active inverted>
                            <Loader active  indeterminate inline='centered' />
                        </Dimmer>
                    }
                >
                    <Switch>
                        <Route exact path="/" render={() => <HomeContainer _openModal={this._openModal} />} />
                        <Route path="/about" render={() => <About _openModal={this._openModal} />} />
                        <Route path="/contact" render={() => <ContactContainer _openModal={this._openModal} />} />
                        <Route path="/projects" render={() => <Projects _openModal={this._openModal} />} />
                        <Route component={NotFound} />
                    </Switch>
                
                </Suspense>
                
                <BookAppointment modalOpen={modalOpen} _closeModal={this._closeModal} _openModal={this._openModal} service={service}/>

            </React.Fragment>


        );

    }

}